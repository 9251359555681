import React from 'react';
import Mailchimp from 'react-mailchimp-form';
import './MailchimpForm.css';

const MailchimpForm = () => {
    return (
    <Mailchimp
    action='https://gmail.us1.list-manage.com/subscribe/post?u=ef3f8cb51dde8d446f08efa61&amp;id=e028a227b0' 
    
    fields={[
        {
        name: 'EMAIL',
        placeholder: 'Email',
        type: 'email',
        required: true
        },
        {
        name: 'FNAME',
        placeholder: 'name',
        type: 'text',
        required: true
        }
    ]}
    messages = {
        {
        sending: "Sending...",
        success: "Thank you for subscribing!",
        error: "An unexpected internal error has occurred.",
        empty: "You must write an e-mail.",
        duplicate: "Too many subscribe attempts for this email address",
        button: "Subscribe!"
        }
    }
    className="mailchimp"
    />
    );
  }
   
  export default MailchimpForm;