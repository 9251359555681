import React from 'react';
import tallhouseLogo from './tallhouse-logo.png';
import './App.css';
import MailchimpForm from './MailchimpForm/MailchimpForm';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={tallhouseLogo} className="App-logo" alt="logo" />
        <p>
          Welcome to tall.house, the official website of Tallhouse.
        </p>
        <a href="#events">See events</a>
        <a href="#newsletter">Newsletter</a>
        <a href="#golf-course">Golf Course</a>
      </header>
      <div id="events" className="calendar">
        <h2>Tallhouse Events</h2>
        <iframe
          className="iframe"
          title="tallhouse-calendar"
          src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=czZ0aDhxZmNhdWJhazY1bXJyNGg0ZTVoNGNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23D81B60&amp;mode=AGENDA"
          scrolling="no"
        />
      </div>
      <div id="newsletter" className="newsletter">
        <h2>Tallhouse Newsletter</h2>
        <p>
          Subscribe for meme-y content, delivered right to your inbox.
        </p>
        <MailchimpForm />
      </div>
      <div id="golf-course" className="calendar">
        <h2>Tallhouse National Golf Course</h2>
        <p><a href="https://forms.gle/G4nLuouVrCivs2ya9">Go to score sheet.</a></p>
        <iframe className="iframe" title="tallhouse-golf-course" src="https://docs.google.com/presentation/d/e/2PACX-1vT5jOmSXwIaM9JM5Ef1gC-Dvl7QWQgAxhoa0ws9VScUir9imOZW1xhgBYNDUkFbwCHLdrHya1liG4Y2/embed?start=false&loop=false&delayms=3000"  scrolling="no" allowFullScreen={true} ></iframe>
      </div>
    </div>
  );
}

export default App;
